<template>
  <div class="container-fluid">
    <Header />
    <div class="mx-auto mt-1 content-outer-container">
      <div class="header">
        <div class="headerLeft">
          <button
            id="friendgetfriends_cufriend_gotopagfriendgetfriendse_button"
            @click="gotoPage('FriendGetFriends')"
          >
            <img
              src="@/assets/images/arrowBack.svg"
              alt=""
              width="18"
              height="18"
            />
          </button>
          <div class="headerText">
            <div class="mainText">Create Friend Get Friends</div>
          </div>
        </div>
      </div>
      <div class="mainContainer">
        <MainForm
          :mode="mode"
          :data="data"
          :isActive="isActive"
          :maxPoint="maxPoint"
          :imgPreview="imgPreview"
          :onCreate="createFGF"
          :checkLimit="checkLimit"
          :updateFGF="updateFGF"
          :storeName="this.storeName"
        />
      </div>
    </div>
    <AlertModal
      :isOpenModal="alertModalSetting.isOpenModal"
      :modalType="alertModalSetting.modalType"
      :title="alertModalSetting.title"
      :details="alertModalSetting.details"
    />
  </div>
</template>

<script>
import Account from '@/helper/AccountHelper.js'
import Mixin from '@/mixin/Mixin'
import Header from '@/components/Layout/Header'
import MainForm from './component/MainForm'
import BzbsUserPermission from '@/core/UserPermission/service/BzbsUserPermission'
import BzbsUserFGF from '@/core/FriendgetFriends/service/BzbsFriendgetFriends'
import moment from 'moment'
import AlertModal from '@/components/Modal/Component/alertmodal2'

export default {
  name: 'FriendGetFriendsCU',
  components: {
    Header,
    MainForm,
    AlertModal,
  },
  mixins: [Mixin],
  data() {
    return {
      mode: '',
      data: {
        date: [],
      },
      storeName: '',
      brandId: '',
      rowkey: '',
      isActive: false,
      imgPreview: '',
      maxPoint: 'Unlimit',
      alertModalSetting: {
        title: '',
        detail: '',
        modalType: '',
        isOpenModal: false,
      },
    }
  },
  created() {
    this.handleFooter(true)
    Account.bzbsAnalyticTracking(
      'get_friend_create_page',
      'get_friend_create',
      'view_get_friend_create',
      'on view',
    )
    this.getStoreName()
  },
  mounted() {
    this.mode = this.$route.params.mode
    if (this.mode === 'edit') {
      this.getActiveFGF()
    }
  },
  methods: {
    gotoPage(page) {
      Account.bzbsAnalyticTracking(
        'get_friend_create_page',
        'get_friend_create',
        'click_get_friend_back',
        'on click',
      )
      this.$router.push({
        name: page,
      })
    },
    checkLimit(event) {
      this.maxPoint = event.target.value
      console.log(
        '🚀 ~ file: CUFriend.vue ~ line 101 ~ checkLimit ~ this.maxPoint',
        this.maxPoint,
      )
      if (this.maxPoint === 'Unlimit') {
        this.data.max_account = 0
      } else {
        this.data.max_account = 1
      }
    },
    alertModalAction(title, details, modalType, isOpenModal) {
      this.alertModalSetting.title = title
      this.alertModalSetting.details = details
      this.alertModalSetting.modalType = modalType
      this.alertModalSetting.isOpenModal = isOpenModal
      if (modalType == 'success' && isOpenModal) {
        setTimeout(() => {
          this.alertModalSetting.isOpenModal = false
          this.$router.push({ name: 'FriendGetFriends' })
        }, 3000)
      } else {
        if (isOpenModal)
          setTimeout(() => {
            this.alertModalSetting.isOpenModal = false
          }, 3000)
      }
    },
    getStoreName() {
      BzbsUserPermission.getCRMPlusStoreInfo()
        .then(res => {
          this.storeName = res.data.BusinessName || '-'
          this.brandId = res.data.EwalletBrandId || '-'
          if (this.mode === 'create') {
            this.data = {
              name: this.lbl['fgf-inputtitle'],
              description: this.lbl['fgf-inputdetail'],
              referral_points: 1,
              user_points: 0,
              max_account: 0,
              isActive: false,
              usetocondition: true,
              validateEarnAndRedeem: true,
              joinDaysState: true,
              joinDays: 1,
              date: [],
              image: '',
              invite_message:
                this.lbl['fgf-inputmessage1'] +
                ' ' +
                this.storeName +
                ' ' +
                this.lbl['fgf-inputmessage2'],
            }
          }
        })
        .catch(err => console.log(err))
    },
    createFGF(data) {
      this.handleLoading(true)
      Account.bzbsAnalyticTracking(
        'get_friend_create_page',
        'get_friend_create',
        'click_get_friend_save',
        'on click',
      )
      BzbsUserFGF.createFGF(data)
        .then(res => {
          console.log('BzbsUserFGF', res)
          this.alertModalAction(
            this.lbl['alert-box-success-header'],
            this.lbl['fgf-successcreatetext'],
            'success',
            true,
          )
        })
        .catch(err => console.log(err))
        .finally(() => this.handleLoading(false))
    },
    updateFGF(data) {
      this.handleLoading(true)
      BzbsUserFGF.updateFGF(data)
        .then(res => {
          console.log('BzbsUserFGF', res)
          this.alertModalAction(
            this.lbl['alert-box-success-header'],
            this.lbl['fgf-successupdate'],
            'success',
            true,
          )
        })
        .catch(err => console.log(err))
        .finally(() => this.handleLoading(false))
    },
    getActiveFGFByRowKey(rowkey) {
      this.handleLoading(true)
      BzbsUserFGF.getActiveFGFByRowKey(rowkey)
        .then(res => {
          console.log('getActiveFGFByRowKey', res)
        })
        .catch(err => console.log(err))
        .finally(() => this.handleLoading(false))
    },
    getActiveFGF() {
      this.handleLoading(true)
      BzbsUserFGF.getActiveFGF()
        .then(res => {
          console.log('getActiveFGF', res.data)
          let item = res.data
          this.rowkey = item.RowKey
          this.isActive = item.Status === 'Create' || item.Status === 'Publish'
          this.imgPreview = item.ImageUrl
          this.maxPoint = item.MaximumAccount > 0 ? 'Limit' : 'Unlimit'
          this.data = {
            name: item.Name,
            rowkey: item.RowKey,
            description: item.Description,
            referral_points: item.ReferralPoints,
            user_points: item.UserPoints,
            max_account: item.MaximumAccount,
            joinDays: item.MinimumFriendGetFriendJoinDays,
            joinDaysState: item.MinimumFriendGetFriendJoinDays > 0,
            validateEarnAndRedeem: item.ValidateFriendGetFriendEarnAndRedeem,
            date: [
              moment(item.StartDate * 1000),
              moment(item.ExpireDate * 1000),
            ],
            invite_message: item.InviteMessage,
          }
          console.log(
            '🚀 ~ file: CUFriend.vue ~ line 204 ~ getActiveFGF ~ this.data',
            this.data,
          )
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => this.handleLoading(false))
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
  color: #212121;
  border-bottom: 2px solid #f5f5f5;
  .headerLeft {
    display: flex;
    align-items: center;
    gap: 16px;
    button {
      width: 69px;
      height: 42px;
      background-color: #fff;
      border: solid 1px #757575;
      padding: 5px;
      border-radius: 8px;
    }
  }
  .headerText {
    .mainText {
      font-size: 24px;
      font-weight: normal;
    }
  }
}
.mainContainer {
  padding: 30px;
}
</style>
